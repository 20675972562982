@import "github-markdown-css";
@import "katex/dist/katex.min.css";


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-bg: #1e1e2f;
    --secondary-bg: #282c34;
    --primary-text: #ffffff;
    --secondary-text: #a1a1aa;
    --highlight: #61dafb;
}

body {
    margin: 0;
    font-family: 'Fira Code', Menlo, Monaco, 'Courier New', monospace;
    background-color: var(--primary-bg);
    color: var(--primary-text);
}

textarea {
    font-family: 'Fira Code', monospace;
    background-color: var(--primary-bg);
    color: var(--primary-text);
    border: 1px solid var(--highlight);
}

a {
    color: var(--highlight);
}

pre {
    background-color: var(--primary-bg);
    color: var(--primary-text);
    border: 1px solid var(--highlight);
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    line-height: 1.5;
}

code {
    font-family: 'Fira Code', monospace;
}

.markdown-body {
    font-family: 'Fira Code', monospace;
    font-size: 16px;
    line-height: 1.8;
    color: var(--primary-text);
    background-color: var(--secondary-bg);
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--highlight);
}

.markdown-body h1, .markdown-body h2, .markdown-body h3 {
    color: var(--highlight);
    margin-top: 16px;
    margin-bottom: 8px;
}

.markdown-body p {
    margin-bottom: 16px;
}

.markdown-body code {
    font-family: 'Fira Code', monospace;
    background-color: var(--primary-bg);
    color: var(--highlight);
    padding: 4px 8px;
    border-radius: 4px;
}

.markdown-body pre {
    background-color: var(--primary-bg);
    color: var(--primary-text);
    padding: 12px;
    border-radius: 8px;
    overflow-x: auto;
}

.markdown-body ul,
.markdown-body ol {
  padding-left: 20px; /* 增加缩进 */
  margin-bottom: 16px; /* 列表与其他元素之间增加间距 */
}

.markdown-body li {
  margin-bottom: 8px; /* 列表项之间增加间距 */
  line-height: 1.6; /* 提高行高 */
}

.markdown-body li > ul,
.markdown-body li > ol {
  margin-top: 8px; /* 嵌套列表顶部增加间距 */
}

.markdown-body li::marker {
  color: var(--highlight); /* 列表符号颜色 */
}

.markdown-body ul {
  list-style-type: disc; /* 使用圆点作为符号 */
}

.markdown-body ol {
  list-style-type: decimal; /* 使用数字作为符号 */
}


.markdown-body blockquote {
    color: var(--secondary-text);
    border-left: 4px solid var(--highlight);
    padding-left: 16px;
    margin: 16px 0;
    font-style: italic;
}


footer {
    background-color: var(--secondary-bg);
}
